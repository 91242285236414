/* ==================================================
AJAX Form Submission

Submits the form to Netlify via AJAX, hides the form
and displays a confirmation message
===================================================*/

const handleSubmit = (e) => {
  e.preventDefault();
  let contactForm = document.getElementById("contact-form");
  let formData = new FormData(contactForm);
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(() => {
    	document.querySelector('.form-main').classList.add('hide');
    	document.querySelector('.form-thanks').classList.remove('hide');
		// Send event to GTM
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'formLocation': 'footer',
			'event': 'form_submitted'
		});
    })
    .catch((error) => alert(error));
};

let contactForm = document.querySelector("#contact-form");

if(contactForm) {
	contactForm.addEventListener("submit", handleSubmit);
}
//Lazyload recaptcha
let hasRecaptcha = document.querySelector('.load-recaptcha');

if(hasRecaptcha) {
  function reCaptchaOnFocus() {
    var head = document.getElementsByTagName('head')[0]
    var script = document.createElement('script')
    script.type = 'text/javascript';
    script.src = 'https://www.google.com/recaptcha/api.js'
    head.appendChild(script);

    document.querySelectorAll('.load-recaptcha').forEach(el => el.removeEventListener('focus', reCaptchaOnFocus))
  };
  // add initial event listener to the form inputs
  document.querySelectorAll('.load-recaptcha').forEach(el => el.addEventListener('focus', reCaptchaOnFocus, false));
}


/* ==================================================
Mobile nav toggle

Toggle mobile nav on click
===================================================*/
document.addEventListener('alpine:init', () => {

	Alpine.bind('Modal', () => ({
		class: 'modal',
		role: 'dialog',
		'aria-modal': 'true',
	}));

	Alpine.store('sideNav', {
	  open: false,

	  toggle() {
	    this.open = !this.open;
	  }
	});

	Alpine.data('sideNavDropdown', (id= '') => ({
	  get expanded() {
	    return this.active === id;
	  },
	  set expanded(value) {
	    this.active = value ? id : null
	  }
	}));

	Alpine.store('currentModal', '');

	// Modal trigger
	Alpine.data('modalTrigger', () => ({
	    openModal() {
	      Alpine.store('currentModal', this.$el.hash)
	    }
	}));

	Alpine.data('modal', () => ({
		init() {
			Alpine.effect(() => {
          this.open = `#${this.$el.id}` == Alpine.store('currentModal')
      })
		},

		closeModal() {
			Alpine.store('currentModal', '');
		},

		open: false,

	}));

	// Tabs
	Alpine.data('tabs', () => ({
	  selectedId: null,
	  init() {
	      // Set the first available tab on the page on page load.
	      this.$nextTick(() => this.select(this.$id('tab', 1)))
	  },
	  select(id) {
	    this.selectedId = id
	  },
	  isSelected(id) {
	      return this.selectedId === id
	  },
	  whichChild(el, parent) {
	      return Array.from(parent.children).indexOf(el) + 1
	  }
	}));

	Alpine.bind('TabButton', () => ({
	  type: 'button',

	  '@click'() {
	  	slider.destroy();
	  	setTimeout(() => {
	  		slider = new Glide(`.unit-galleries.${this.$el.id}-slider`).mount()
	  	}, 300)

	    return this.select(this.$el.id)
	  },

	  ':aria-selected'() {
	    return this.isSelected(this.$el.id)
	  },

	  ':class'() {
	    return this.isSelected(this.$el.id) ? 'active' : ''
	  },

	  ':id'() {
	    return this.$id('tab', this.whichChild(this.$el.parentElement, this.$refs.tablist))
	  },

	  ':tabindex'() {
	    return this.isSelected(this.$el.id) ? 0 : -1
	  }

	}));

	Alpine.bind('TabList', () => ({
	  '@keydown.right.prevent.stop'() {
	    return this.$focus.wrap().next()
	  },

	  '@keydown.home.prevent.stop'() {
	    return this.$focus.first()
	  },

	  '@keydown.pageup.prevent.stop'() {
	    return this.$focus.first()
	  },

	  '@keydown.left.prevent.stop'() {
	    return this.$focus.wrap().prev()
	  },

	  '@keydown.end.prevent.stop'() {
	    return this.$focus.last()
	  },

	  '@keydown.page-down.prevent.stop'() {
	    return this.$focus.last()
	  },
	}));

	Alpine.bind('TabPanel', () => ({
	  'x-show'() {
	    return this.isSelected(this.$id('tab', this.whichChild(this.$el, this.$el.parentElement)))
	  },

	  ':aria-labelledby'() {
	    return this.$id('tab', this.whichChild(this.$el, this.$el.parentElement))
	  },

	}));


});

let sliderElement = document.querySelector('.unit-galleries.tab-1-1-slider');
let slider = new Glide(sliderElement, {
	type: 'carousel'
}).mount()

// let imageCaptionSliderElement = document.querySelector('.image-caption-slider');
// let imageCaptionSlider = new Glide(imageCaptionSliderElement, {
// 	type: 'carousel'
// }).mount()
